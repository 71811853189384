import {isRouteErrorResponse, useRouteError} from 'react-router-dom'

import NotFoundPage from './NotFoundPage'
import UnhandledErrorPage from './UnhandledErrorPage'

export default function ErrorPage(): React.ReactNode {
  const error = useRouteError()

  return isRouteErrorResponse(error) && error.status === 404 ? (
    <NotFoundPage />
  ) : (
    <UnhandledErrorPage />
  )
}
