import {Button} from '@kensho/neo'
import {useNavigate} from 'react-router-dom'

import Page from './Page'

export default function UnhandledErrorPage(): React.ReactNode {
  const navigate = useNavigate()

  function navigateToHome(): void {
    navigate('/')
  }

  return (
    <Page>
      <div className="py-40">
        <div className="mb-14 flex flex-col items-center justify-center gap-6 md:flex-row">
          <svg
            width="465"
            height="110"
            viewBox="0 0 465 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 59.568V1.606H25.55V58.692C25.55 70.226 31.682 78.548 45.26 78.548C58.692 78.548 64.824 70.226 64.824 58.692V1.606H90.374V59.422C90.374 83.658 76.504 100.74 45.26 100.74C14.016 100.74 0 83.512 0 59.568Z"
              fill="#007694"
            />
            <path
              d="M198.598 98.988H173.486V59.86H133.044V98.988H107.932V1.606H133.044V37.96H173.486V1.606H198.598V98.988Z"
              fill="#007694"
            />
            <path
              d="M248.54 50.37C248.54 20.732 270.878 0 300.662 0C330.446 0 352.638 20.732 352.638 50.37C352.638 80.008 330.446 100.74 300.662 100.74C270.878 100.74 248.54 80.008 248.54 50.37ZM327.088 50.37C327.088 34.456 316.722 22.192 300.662 22.192C284.456 22.192 274.09 34.456 274.09 50.37C274.09 66.138 284.456 78.548 300.662 78.548C316.722 78.548 327.088 66.138 327.088 50.37Z"
              fill="#007694"
            />
            <path
              d="M455.951 98.988H430.839V59.86H390.397V98.988H365.285V1.606H390.397V37.96H430.839V1.606H455.951V98.988Z"
              fill="#007694"
            />
            <path
              d="M8 9.106H7.5V9.606V67.568C7.5 79.6362 11.0338 90.0717 18.5626 97.4892C26.0913 104.907 37.5478 109.24 53.26 109.24C68.971 109.24 80.3922 104.944 87.8853 97.5248C95.3783 90.106 98.874 79.6334 98.874 67.422V9.606V9.106H98.374H72.824H72.324V9.606V66.692C72.324 72.3756 70.8134 77.2047 67.7095 80.6083C64.6128 84.0042 59.8691 86.048 53.26 86.048C46.5763 86.048 41.7961 84.0032 38.6817 80.6073C35.5606 77.204 34.05 72.3755 34.05 66.692V9.606V9.106H33.55H8ZM206.598 107.488H207.098V106.988V9.606V9.106H206.598H181.486H180.986V9.606V45.46H141.544V9.606V9.106H141.044H115.932H115.432V9.606V106.988V107.488H115.932H141.044H141.544V106.988V68.36H180.986V106.988V107.488H181.486H206.598ZM463.951 107.488H464.451V106.988V9.606V9.106H463.951H438.839H438.339V9.606V45.46H398.897V9.606V9.106H398.397H373.285H372.785V9.606V106.988V107.488H373.285H398.397H398.897V106.988V68.36H438.339V106.988V107.488H438.839H463.951ZM308.662 7.5C278.624 7.5 256.04 28.4344 256.04 58.37C256.04 88.3056 278.624 109.24 308.662 109.24C338.702 109.24 361.138 88.3038 361.138 58.37C361.138 28.4362 338.702 7.5 308.662 7.5ZM308.662 30.692C316.557 30.692 323.027 33.7028 327.528 38.6497C332.032 43.6013 334.588 50.5207 334.588 58.37C334.588 73.9092 324.401 86.048 308.662 86.048C300.695 86.048 294.188 83.0011 289.668 78.0355C285.145 73.0651 282.59 66.1454 282.59 58.37C282.59 50.5208 285.146 43.6017 289.668 38.6504C294.186 33.7037 300.693 30.692 308.662 30.692Z"
              stroke="#00B9E8"
            />
          </svg>
          <div>
            <h1 className="text-5xl">Sorry...</h1>
            <p className="text-2xl">Something went wrong.</p>
            <p className="text-2xl">Please refresh the page.</p>
          </div>
        </div>
        <div className="mx-auto flex justify-center">
          <Button onClick={navigateToHome} intent="primary">
            <div className="w-52">Back to homepage</div>
          </Button>
        </div>
      </div>
    </Page>
  )
}
