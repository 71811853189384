import {useState} from 'react'
import {InputGroup, Checkbox, Button, Icon, Spinner, Callout} from '@kensho/neo'
import {Link} from 'react-router-dom'

import {LINKS} from '../constants'

import FileUpload from './FileUpload'
import ExternalLink from './ExternalLink'
import RadioGroup, {Radio} from './RadioGroup'
import SubmissionInstructions from './SubmissionInstructions'

const MAX_FILE_SIZE_BYTES = 5 * 1000000 // 5mb

// TODO: Replace with Neo input validation handling when available.
// This leans on the browser's input validation to validate/show errors.
function handleRequiredInputChange<T>(
  type: 'string' | 'number',
  setState: React.Dispatch<React.SetStateAction<T>>,
): React.ChangeEventHandler<HTMLInputElement> {
  return (event) => {
    const {value} = event.target
    event.target.setCustomValidity('')

    if (type === 'number' && Number.isNaN(Number(value))) {
      event.target.setCustomValidity('Please enter a number.')
    }

    if (value.trim() === '') event.target.setCustomValidity('This field is required.')

    event.target.reportValidity()
    setState(value as T)
  }
}

const FILE_CHECKS = [
  {
    check: (file: File) => file.type === 'text/csv',
    message: 'Invalid file type. Please try a different file.',
  },
  {
    check: (file: File) => file.size < MAX_FILE_SIZE_BYTES,
    message: 'This file is larger than our 5 MB limit. Please try a different file.',
  },
]

function isFileValid(file: File): boolean {
  return FILE_CHECKS.every(({check}) => check(file))
}

function getFileError(file: File): string | null {
  const maybeError = FILE_CHECKS.find(({check}) => !check(file))
  return maybeError ? maybeError.message : null
}

interface SubmissionFormProps {
  isSubmitting: boolean
  onSubmit: (data: FormData) => void
}

export default function SubmissionForm(props: SubmissionFormProps): React.ReactNode {
  const {onSubmit, isSubmitting} = props
  const [hasAgreed, setHasAgreed] = useState(false)
  const [modelName, setModelName] = useState('')
  const [organization, setOrganization] = useState('')
  const [modelSize, setModelSize] = useState<string | null>('')
  const [organizationWebsite, setOrganizationWebsite] = useState('')
  const [hasOptedOutFromOrgAffiliation, setHasOptedOutFromOrgAffiliation] = useState(false)
  const [answersFile, setAnswersFile] = useState<File | null>(null)
  const [isModelOpenSource, setIsModelOpenSource] = useState(true)
  const [modelUrl, setModelUrl] = useState('')

  // We rely on the browser to handle validation for <input /> elements. These additional checks
  // are outside the scope of what the browser can handle given Neo constraints.
  function canSubmit(): boolean {
    return hasAgreed && answersFile != null && isFileValid(answersFile)
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault()
    if (!canSubmit()) return

    const formData = new FormData()

    formData.append('model_name', modelName.trim())
    formData.append('organization_name', organization.trim())
    formData.append('organization_url', organizationWebsite.trim())
    formData.append('affiliate_organization', (!hasOptedOutFromOrgAffiliation).toString())
    formData.append('data_agreement', hasAgreed.toString())
    if (modelSize !== null) formData.append('model_size_billions', modelSize.trim())
    // The file won't be null since the validation passed above but this check is included for TS
    if (answersFile !== null) formData.append('file', answersFile)
    formData.append('is_open_source', isModelOpenSource.toString())
    if (isModelOpenSource) formData.append('model_url', modelUrl.trim())

    onSubmit(formData)
  }

  const fileErrorMessage = answersFile != null ? getFileError(answersFile) : null

  return (
    <>
      <section className="max-w-4xl">
        <h2 className="mb-7 text-lg font-semibold">
          Step 1 - Download the question json file & Process each example with your model
        </h2>
        <SubmissionInstructions />
      </section>

      <hr className="my-8" />

      <h2 className="mb-7 text-lg font-semibold">Step 2 - Add model details</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-x-20 gap-y-10 md:grid-cols-2">
          <InputGroup
            label="Model name*"
            onChange={handleRequiredInputChange('string', setModelName)}
            value={modelName}
          />
          <InputGroup
            label="Organization*"
            onChange={handleRequiredInputChange('string', setOrganization)}
            value={organization}
          />
          <div className="flex flex-col gap-2">
            <InputGroup
              label="Number of parameters (in billions)*"
              description="Total model parameters including the embedding and inactive parameters to the 100 millions place"
              onChange={handleRequiredInputChange('number', setModelSize)}
              value={modelSize || ''}
              disabled={modelSize === null}
            />
            <Checkbox
              label="Private/unknown"
              onChange={(checked) => {
                setModelSize(checked ? null : '')
              }}
              value={modelSize === null}
            />
          </div>
          <div className="flex flex-col gap-2">
            <InputGroup
              label="Organization website*"
              description="Note that we will link your website from our leaderboard unless opted out below"
              type="url"
              // The browser will ensure the URL is valid, but will allow "" to pass validation.
              // Neo doesn't allow `required` currently so we require outside validation.
              onChange={handleRequiredInputChange('string', setOrganizationWebsite)}
              value={organizationWebsite}
            />
            <Checkbox
              label="Opt out of organization affiliation"
              onChange={setHasOptedOutFromOrgAffiliation}
              value={hasOptedOutFromOrgAffiliation}
            />
          </div>
          <div className="flex flex-col gap-2">
            <RadioGroup label="Is your model open sourced?*">
              <Radio
                name="model-open-source"
                label="Yes"
                checked={isModelOpenSource}
                onChange={() => setIsModelOpenSource(true)}
              />
              <Radio
                name="model-open-source"
                label="No"
                checked={!isModelOpenSource}
                onChange={() => setIsModelOpenSource(false)}
              />
            </RadioGroup>
            {isModelOpenSource && (
              <InputGroup
                label="(Optional) Link to model"
                description="Link to Hugging Face preferred"
                type="url"
                onFocus={(event) => event.target.reportValidity()}
                onChange={(event) => {
                  setModelUrl(event.target.value)
                  event.target.reportValidity()
                }}
                value={modelUrl}
              />
            )}
          </div>
        </div>

        <hr className="my-8" />

        <section className="max-w-4xl">
          <h2 className="mb-7 text-lg font-semibold">Step 3 - Upload answers</h2>
          <p>Upload the answers as a single csv file.</p>
          <p>
            Our demo pipeline provides an{' '}
            <ExternalLink to={LINKS.DEMO_PIPELINE}>example csv</ExternalLink> with the correct
            format: question ID and answer columns with no header.
          </p>
          <div className="my-5 max-w-lg">
            <FileUpload
              accept={['text/csv']}
              onSelect={(fileList) => setAnswersFile(fileList ? fileList[0] : null)}
            />
            <div className="mt-4 text-sm">Accepted format: csv</div>
            {fileErrorMessage != null && (
              <Callout title="File selection error" intent="danger">
                {fileErrorMessage}
              </Callout>
            )}
            {answersFile && isFileValid(answersFile) && (
              <div className="mt-4 flex items-center gap-3 rounded-lg border border-gray-300 p-4">
                <div className="flex-none text-brand-700">
                  <Icon icon="DocumentIcon" />
                </div>
                <div className="grow">{answersFile.name}</div>
                <div className="-m-2">
                  <Button
                    minimal
                    icon="XMarkIcon"
                    intent="primary"
                    onClick={() => setAnswersFile(null)}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
        <hr className="my-8" />
        <div className="flex flex-col gap-3">
          <p className="text-sm">
            To participate in S&P’s AI Benchmarks by Kensho, please carefully review and agree to
            the{' '}
            <Link to="/agreement" target="_blank" className="text-brand-700 underline">
              Benchmark Participation Agreement
            </Link>
            .
          </p>
          <Checkbox
            label="I have read and agree to the Benchmark Participation Agreement."
            onChange={setHasAgreed}
            value={hasAgreed}
          />
        </div>
        <div className="my-9 flex w-full flex-col md:w-64">
          {/* TODO: Use a pending button when ready (https://github.kensho.com/kensho/neo/issues/70) */}
          <Button
            disabled={!canSubmit() || isSubmitting}
            intent="primary"
            size="large"
            type="submit"
          >
            {isSubmitting ? <Spinner /> : 'Submit'}
          </Button>
        </div>
      </form>
    </>
  )
}
