import {useState} from 'react'
import {Button, Callout} from '@kensho/neo'
import {useLogger} from '@kensho/lumberjack'
import {ZodError} from 'zod'
import {Link} from 'react-router-dom'

import submit from '../api/submit'
import SubmissionForm from '../components/SubmissionForm'
import ExternalLink from '../components/ExternalLink'
import {LINKS} from '../constants'

import Page from './Page'

export default function SubmissionPage(): React.ReactNode {
  const log = useLogger()
  const [submissionState, setSubmissionState] = useState<null | 'submitting' | 'success' | 'error'>(
    null,
  )

  function handleSubmit(data: FormData): void {
    setSubmissionState('submitting')

    submit(data)
      .then(() => {
        setSubmissionState('success')
      })
      .catch((error) => {
        const context = error instanceof ZodError ? {issues: JSON.stringify(error.issues)} : error
        log.error('Submission failed', context)
        setSubmissionState('error')
      })
  }

  return (
    <Page>
      <section className="mx-auto max-w-screen-lg px-4 py-12 lg:px-0">
        {submissionState === 'error' && (
          <div className="mb-9">
            <Callout intent="danger" title="Submission failed">
              We are sorry but there has been a problem submitting your form.
              <ExternalLink to={LINKS.SUPPORT_EMAIL}>Contact support</ExternalLink>
            </Callout>
          </div>
        )}
        {submissionState === 'success' ? (
          <div className="mx-auto max-w-4xl py-12">
            <h1 className="text-2xl font-semibold">Thank you for submitting!</h1>
            <p className="mt-10">
              We have received your submission. Please allow 2-4 business days for a Kensho team
              member to review your submission. You will receive an email notification with details
              about your scores.
            </p>
            <div className="my-24 flex w-full justify-center">
              <Link to="/">
                <Button size="large" intent="primary">
                  <div className="w-52">Back to homepage</div>
                </Button>
              </Link>
            </div>
          </div>
        ) : (
          <>
            <h1 className="text-2xl font-semibold">Let’s submit!</h1>
            <p className="mt-2">
              Submission involves running your model on our provided test examples and then
              uploading the answers.
            </p>
            <hr className="my-8" />
            <SubmissionForm
              onSubmit={handleSubmit}
              isSubmitting={submissionState === 'submitting'}
            />
          </>
        )}
      </section>
    </Page>
  )
}
