export default function camelCaseKeys(data: unknown): unknown {
  if (Array.isArray(data)) {
    return data.map(camelCaseKeys)
  }

  if (typeof data === 'object' && data != null) {
    return Object.entries(data).reduce((acc, [key, val]) => {
      const newKey = key
        .toLowerCase()
        .replace(/([_][a-z])/g, (group) => group.slice(1).toUpperCase())
      return {...acc, [newKey]: camelCaseKeys(val)}
    }, {})
  }

  return data
}
