import {Icon} from '@kensho/neo'
import {useState} from 'react'

import {LINKS} from '../constants'

import ExternalLink from './ExternalLink'

const FAQ: {question: string; answer: React.ReactNode}[] = [
  {
    question: 'What is the difference between this leaderboard and other LLM evaluations?',
    answer:
      "While there are existing LLM benchmark datasets (e.g., MT-Bench, BIG-bench, SuperGLUE, etc.), our benchmark and corresponding leaderboard focus exclusively on measuring models' ability to understand and perform tasks concerning finance and business. Existing finance-based evaluations typically combine several existing datasets to form a benchmark. In contrast, all of our datasets and tasks involve either: (a) extending, augmenting, and refining existing datasets; or (b) creating new datasets from scratch. Furthermore, many existing tasks allow for free-text answers, which naturally require relying on automatic and often imperfect evaluations—due to the need to parse answers and/or estimate semantics. Instead, our questions allow for precise, objective evaluations since the responses are either numerical or options in the case of multiple-choice questions.",
  },
  {
    question: 'When can I expect to receive the results for my submission?',
    answer:
      'Please allow 2-4 business days for a Kensho team member to review and approve your submission. You will receive an email notification with details about your scores.',
  },
  {
    question: 'How many times can I submit?',
    answer: 'Once per team, per business day.',
  },
  {
    question: 'Is there a participation fee?',
    answer:
      'Participants bear the cost of running the model. However, this is a free benchmarking leaderboard.',
  },
  {
    question: 'What am I expected to submit?',
    answer: (
      <>
        In our submission process, you are only required to provide the{' '}
        <span className="font-bold">outputs</span> of your model; there is no need to share the
        actual model itself.
      </>
    ),
  },
  {
    question: 'Do I need to give my LLM extra information to accurately run the tests?',
    answer:
      'Your LLM simply needs to generate a response to each question in our evaluation set; nothing additional is needed. No pre-training is necessary. Please see the submission page for full details.',
  },
  {
    question:
      'Do I need to provide additional information to my model to generate responses to the question?',
    answer:
      'No. All the information required to answer the questions is contained within the JSON file provided. The participants do not need to pre-train a LLM or access external data sources to answer any question.',
  },
  {
    question: 'How will you manage my submission?',
    answer:
      'Because users submit their model’s output, not the model itself, we do not see or collect your model code or model weights. We use submitted outputs to calculate your score, which will be populated on the leaderboard. Any user who wishes to have their scores removed from the leaderboard can request so at any time.',
  },
  {
    question: 'What are the ranking criteria?',
    answer: (
      <ul>
        <li>
          Our evaluation set comprises 600 questions spanning three task categories. Each category
          consists of one or more tasks, each containing a varying number of questions (further
          details are provided on the submissions page).
        </li>
        <li>
          Our primary ranking criterion is the average accuracy across all categories, i.e., the
          Macro Average. However, for every submission, you will be able to view its category-level
          average score.
        </li>
      </ul>
    ),
  },
  {
    question: 'Will the ranking give me feedback on why my LLM did or didn’t rank well?',
    answer:
      'You will receive feedback in the form of a score for each of the individual tasks, along with the aggregate scores across the three categories: quantitative reasoning, financial data extraction, and domain knowledge.',
  },
  {
    question: 'Is there an incentive or reward for ranking well with S&P AI Benchmarks?',
    answer:
      'Teams at other organizations will be able to see your score on the benchmark on the leaderboard and can link to your LLM from there.',
  },
  {
    question: 'Do my results have to be displayed on the leaderboard?',
    answer: (
      <>
        By default, your model’s score will be displayed on the leaderboard. If you prefer not to
        have your results shown, you can reach out to our team at{' '}
        <ExternalLink to={LINKS.SUPPORT_EMAIL}>benchmarks@kensho.com</ExternalLink> to opt out. By
        allowing your score to remain public, you are benefitting the broader community, as we can
        all collectively learn about model performance—ultimately fueling improvements over time.
      </>
    ),
  },
  {
    question: 'What research is this finance benchmark based on?',
    answer: (
      <>
        The research behind this finance benchmark draws upon various sources, including{' '}
        <ExternalLink to={LINKS.MMLU}>MMLU</ExternalLink>,{' '}
        <ExternalLink to={LINKS.FIN_QA}>FinQA</ExternalLink>, and{' '}
        <ExternalLink to={LINKS.TAT_QA}>TAT-QA</ExternalLink>. These works, along with detailed
        citations and motivations outlined in our paper, have significantly contributed to the
        development of this benchmark.
      </>
    ),
  },
  {
    question: 'Where can I find the evaluation set/questions?',
    answer:
      'Once you click “submit” and sign into your Kensho account, you will have access to the full list of questions.',
  },
  {
    question: 'Am I allowed to prompt the model to improve its ability to answer the questions?',
    answer:
      'You and your team are free to prompt however you wish. You’re not restricted to asking the model the questions verbatim.',
  },
]

interface DetailsProps {
  summary: string
  children: React.ReactNode
}

function Details(props: DetailsProps): React.ReactNode {
  const {summary, children} = props
  const [isOpen, setIsOpen] = useState(false)

  function handleDetailsClick(): void {
    setIsOpen((prev) => !prev)
  }

  return (
    <details open={isOpen || undefined}>
      <summary
        className="flex cursor-pointer list-none items-center gap-4 text-xl hover:text-brand-700 [&::-webkit-details-marker]:hidden"
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            handleDetailsClick()
          }
        }}
        onClick={(event) => {
          event.preventDefault()
          handleDetailsClick()
        }}
      >
        <span className="text-brand-700">
          <Icon icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'} size="large" />
        </span>
        {summary}
      </summary>
      <div className="ml-10 mt-2">{children}</div>
    </details>
  )
}

export default function FrequentlyAskedQuestions(): React.ReactNode {
  return (
    <div className="flex flex-col gap-7">
      {FAQ.map(({question, answer}) => (
        <Details key={question} summary={question}>
          {answer}
        </Details>
      ))}
    </div>
  )
}
