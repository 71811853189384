import {z} from 'zod'

function getCookie(key: string): string {
  const cookies = document.cookie.split('; ')
  const cookie = cookies.map((row) => row.split('=')).find(([cookieKey]) => cookieKey === key)
  return cookie ? cookie[1] : ''
}

export default function fetchResource<T>(
  schema: z.ZodType<T>,
  apiEndpoint: string,
  params?: RequestInit,
  timeout?: number,
): Promise<T> {
  const init = params || {}
  if (init.method === 'POST') {
    init.headers = {...init.headers, 'X-CSRFToken': getCookie('csrftoken')}
  }
  return new Promise((resolve, reject) => {
    fetch(apiEndpoint, init)
      .then((response) => {
        if (response.ok) {
          // Do not attempt to parse JSON from empty responses
          const contentLength = response.headers.get('Content-Length')
          return contentLength && Number(contentLength) === 0 ? undefined : response.json()
        }
        throw response
      })
      .then((value) => schema.parse(value))
      .then(resolve, reject)

    if (timeout && timeout > 0) {
      setTimeout(reject, timeout, new Error('Request timed out'))
    }
  })
}
