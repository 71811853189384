import {init, reactRouterV6BrowserTracingIntegration, wrapCreateBrowserRouter} from '@sentry/react'
import {useEffect} from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

init({
  dsn: 'https://44d84be6e1a842ddac69833915faf747@sentry.kensho.com/231',
  enabled: process.env.NODE_ENV === 'production',
  environment: window.location.hostname,
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
})

export const wrappedCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
