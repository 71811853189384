import {AlertDialog, Button, Dialog} from '@kensho/neo'

import getSignUpUrl from '../getSignUpUrl'
import getLoginUrl from '../getLoginUrl'

interface SignInDialogProps {
  isOpen?: boolean
  onClose?: () => void
}
export default function SignInDialog(props: SignInDialogProps): React.ReactNode {
  const {isOpen = true, onClose} = props

  const content = (
    <div className="flex flex-col items-center justify-center gap-4">
      <a href={getLoginUrl()} rel="noopener">
        <Button intent="primary">
          <div className="w-72">Sign in</div>
        </Button>
      </a>
      <a href={getSignUpUrl()} rel="noopener">
        <Button>
          <div className="w-72">Sign up</div>
        </Button>
      </a>
    </div>
  )

  if (onClose) {
    return (
      <Dialog
        title="Log in or sign up to get started"
        backdrop="dark"
        isOpen={isOpen}
        onClose={onClose}
      >
        {content}
      </Dialog>
    )
  }

  return (
    <AlertDialog
      title="Log in or sign up to get started"
      backdrop="dark"
      isOpen={isOpen}
      onClose={onClose}
    >
      {content}
    </AlertDialog>
  )
}
