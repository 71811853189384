import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import {RouteObject, RouterProvider} from 'react-router-dom'

import AppLoggerProvider from '../providers/AppLoggerProvider'

import createRouter from './createRouter'

interface InitOptions {
  routes: RouteObject[]
}

/** Initializes a client-side React application. */
export default function init(options: InitOptions): void {
  const {routes} = options

  const router = createRouter(routes)

  const app = (
    <StrictMode>
      <AppLoggerProvider>
        <RouterProvider router={router} />
      </AppLoggerProvider>
    </StrictMode>
  )

  const rootElement = document.createElement('div')
  document.body.appendChild(rootElement)

  const root = createRoot(rootElement)
  root.render(app)
}
