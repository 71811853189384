import {createContext, useContext, useId, useMemo} from 'react'
import clsx from 'clsx'

export interface RadioGroupContextType {
  disabled: boolean
  name: string
}

const RadioGroupContext = createContext<RadioGroupContextType | null>(null)

interface RadioProps {
  description?: string
  disabled?: boolean
  label?: string
  name?: string
  onChange?: () => void
  value?: string
  checked?: boolean
}

export function Radio({
  description,
  disabled = false,
  label,
  name,
  checked,
  onChange,
}: RadioProps): React.ReactNode {
  const radioGroup = useContext(RadioGroupContext)
  const groupName = radioGroup ? radioGroup.name : name

  const id = useId()
  const labelId = `${id}-label`
  const descriptionId = `${id}-description`

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          aria-describedby={description ? descriptionId : undefined}
          aria-labelledby={label ? labelId : undefined}
          className="relative h-4 w-4 appearance-none rounded-full bg-white text-brand-700 outline-none outline-2 outline-offset-2 ring-1 ring-inset ring-gray-300 transition-all before:absolute before:left-1 before:top-1 before:h-2 before:w-2 before:scale-0 before:rounded-full before:bg-white before:transition-all before:content-[''] checked:bg-brand-700 checked:ring-transparent checked:before:scale-90 focus-visible:outline-cyan-600 disabled:bg-gray-300 disabled:ring-transparent"
          disabled={disabled || radioGroup?.disabled}
          id={id}
          checked={checked}
          onChange={onChange}
          name={groupName}
          type="radio"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label
          className={clsx('font-medium', disabled ? 'text-gray-300' : 'text-gray-900')}
          htmlFor={id}
        >
          {label}
        </label>
        {description ? (
          <p className="text-gray-500" id={descriptionId}>
            {description}
          </p>
        ) : null}
      </div>
    </div>
  )
}

interface RadioGroupProps {
  children: React.ReactNode
  description?: string
  disabled?: boolean
  label?: React.ReactNode
}

export default function RadioGroup({
  children,
  description,
  disabled = false,
  label,
}: RadioGroupProps): React.ReactNode {
  const id = useId()
  const labelId = `${id}-label`
  const descriptionId = `${id}-description`
  const name = `${id}-name`

  const radioGroupContext = useMemo<RadioGroupContextType>(
    () => ({disabled, name}),
    [disabled, name],
  )

  return (
    <div
      aria-describedby={description ? descriptionId : undefined}
      aria-labelledby={label ? labelId : undefined}
      aria-orientation="vertical"
      className="flex flex-col gap-1.5"
      id={id}
      role="radiogroup"
    >
      {label ? (
        <span className="mb-0.5 block text-sm font-medium text-gray-900" id={labelId}>
          {label}
        </span>
      ) : null}
      <RadioGroupContext.Provider value={radioGroupContext}>{children}</RadioGroupContext.Provider>
      {description ? (
        <span className="mt-0.5 block text-sm leading-6 text-gray-500" id={descriptionId}>
          {description}
        </span>
      ) : null}
    </div>
  )
}
