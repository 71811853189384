export const ENDPOINTS = {
  ME: '/api/v1/users/me',
  LOGIN: '/login',
  LOGOUT: '/logout',
  SIGN_UP: 'https://services.kensho.com/sign-up',
  SUBMIT: '/api/v1/evaluation/submit',
  DOWNLOAD_QUESTIONS: '/api/v1/evaluation/download',
}

export const LINKS = {
  // TODO: Verify/update to external demo pipeline URL when readyc
  DEMO_PIPELINE: 'https://github.com/kensho-technologies/benchmarks-pipeline',
  RESEARCH_PAPER: 'https://arxiv.org/pdf/2311.06602.pdf',
  SUPPORT_EMAIL: 'mailto:benchmarks@kensho.com',
  FIN_QA: 'https://github.com/czyssrs/FinQA',
  TAT_QA: 'https://github.com/NExTplusplus/TAT-QA',
  CONV_FIN_QA: 'https://github.com/czyssrs/ConvFinQA',
  MMLU: 'https://arxiv.org/pdf/2009.03300.pdf',
}
