import clsx from 'clsx'

import {LINKS} from '../constants'

import ExternalLink from './ExternalLink'

type Category = 'domain-knowledge' | 'extraction' | 'reasoning'

interface CategoryInfo {
  name: string
  detail: React.ReactNode
  questionFormats: {
    detail?: React.ReactNode
    format: string
  }[]
}

const CATEGORY_DETAIL_MAP: Record<Category, CategoryInfo> = {
  'domain-knowledge': {
    name: 'Domain Knowledge',
    detail: (
      <>
        This task evaluates the model’s proficiency in financial domain knowledge, encompassing
        business and financial terminology, practices, and formulae. The 131 questions are drawn
        from <ExternalLink to={LINKS.RESEARCH_PAPER}>FinKnow</ExternalLink>, a collection of{' '}
        <span className="font-bold">multiple-choice</span> question-answering datasets from CFA
        practice exams and <ExternalLink to={LINKS.MMLU}>MMLU</ExternalLink>, designed to assess an
        AI model’s proficiency in financial domain knowledge. This task encompasses questions on
        business ethics, microeconomics, and professional accounting.
      </>
    ),
    questionFormats: [
      {
        detail:
          'The questions in this task are formatted as below and include a field `options` with the multiple choice options. Answers for questions in this category are expected to be indices corresponding to the provided options.',
        format: `{\n\t"id": str,\n\t"task": str,\t\t    # 'FinKnow'\n\t"question": str,\n\t"options": List[str]\n}`,
      },
    ],
  },
  extraction: {
    name: 'Quantity Extraction',
    detail: (
      <>
        These tasks assess models’ ability to extract numerical values from various types of
        financial reports. Originally drawn from{' '}
        <ExternalLink to={LINKS.CONV_FIN_QA}>ConvFinQA</ExternalLink> (94 questions) and{' '}
        <ExternalLink to={LINKS.TAT_QA}>TAT-QA</ExternalLink> (129 questions), these questions have
        been curated and augmented for both relevance and completeness. The answer to each question
        is a unique, unambiguous number.
      </>
    ),
    questionFormats: [
      {
        detail:
          'The questions in this task are formatted as below and include the additional context. Answers for questions in this category are expected to be float values.',
        format: `{\n\t"id": str,\n\t"task": str,\t\t    # 'ConvFinQA', 'TAT-QA'\n\t"question": str,\n\t"context": str,\n\t"context_type": str \t# 'text'\n}`,
      },
    ],
  },
  reasoning: {
    name: 'Quantitative Reasoning',
    detail: (
      <>
        These tasks assess models’ capability in answering quantitative questions. Originally
        sourced from <span className="italic">CFA Practice Exams</span> (92 questions),{' '}
        <ExternalLink to={LINKS.FIN_QA}>FinQA</ExternalLink> (49 questions), and{' '}
        <ExternalLink to={LINKS.TAT_QA}>TAT-QA</ExternalLink> (105 questions), these questions draw
        upon a combination of the skills required in Quantity Extraction and Domain Knowledge.
        Computing the correct solution involves both extracting numbers from a given context and
        utilizing domain specific knowledge to apply the correct formulae required to obtain the
        final answer.
      </>
    ),
    questionFormats: [
      {
        detail:
          'There are a number of different question formats in this category. Answers for all the questions in this category are expected to be float values.\n\nSome questions require no context:',
        format: `{\n\t"id": str,\n\t"task": str,\t\t    # 'FinCode'\n\t"question": str\n}`,
      },
      {
        detail: 'Some questions provide string-based context:',
        format: `{\n\t"id": str,\n\t"task": str,\t\t    # 'CodeFinQA'\n\t"question": str,\n\t"context": str,\n\t"context_type": str \t# 'text'\n}`,
      },
      {
        detail: (
          <>
            Some questions provide a table as a JSON object for context. This JSON object can be
            represented in a prompt in different ways, e.g., markdown format or JSON format. In{' '}
            <ExternalLink to={LINKS.DEMO_PIPELINE}>our pipeline</ExternalLink> we have already
            provided a way to do it; however, participants are encouraged to experiment with other
            methods.
          </>
        ),
        format: `{\n\t"id": str,\n\t"task": str,\t\t    # 'CodeTAT-QA'\n\t"question": str,\n\t"context": json,\n\t"context_type": str \t# 'json'\n}`,
      },
    ],
  },
}

interface CategoryDetailProps {
  category: Category
}

export default function CategoryDetail(props: CategoryDetailProps): React.ReactNode {
  const {category} = props
  const {name, detail, questionFormats} = CATEGORY_DETAIL_MAP[category]

  return (
    <div className="mt-7">
      <p>
        <span className="font-bold">{name}: </span>
        {detail}
      </p>
      {questionFormats.map((questionFormat, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={clsx(idx > 0 ? 'mt-8' : 'mt-2')} key={idx}>
          <p className="whitespace-pre-wrap">{questionFormat.detail}</p>
          <pre className="mt-2 w-full max-w-xl bg-zinc-100 p-4 text-sm">
            {questionFormat.format}
          </pre>
        </div>
      ))}
    </div>
  )
}
