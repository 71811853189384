import {Button, Icon, Tabs} from '@kensho/neo'

import {ENDPOINTS, LINKS} from '../constants'

import CategoryDetail from './CategoryDetail'
import ExternalLink from './ExternalLink'

export default function SubmissionInstructions(): React.ReactNode {
  return (
    <>
      <p>
        Download the question json file. Process each example with your model. The answer to each
        question will be a single float value. However, there are many different question types and
        formats. Our <ExternalLink to={LINKS.DEMO_PIPELINE}>demo pipeline</ExternalLink> shows how
        to parse each question type. The specification for each question format is also included in
        the task section below.
      </p>
      <a
        download
        className="my-5 inline-block text-brand-700 underline"
        href={ENDPOINTS.DOWNLOAD_QUESTIONS}
      >
        <Button size="large">
          <div className="flex items-center">
            <span className="mr-4 text-brand-700">
              <Icon icon="DocumentIcon" />
            </span>{' '}
            S&P AI Benchmarks by Kensho Question Set.json{' '}
            <span className="ml-4 lg:ml-20">
              <Icon size="small" icon="ArrowDownTrayIcon" />
            </span>
          </div>
        </Button>
      </a>
      <p>
        S&P AI Benchmarks by Kensho (beta) consists of questions sourced from six distinct finance
        tasks curated for three essential categories: core domain knowledge, quantity extraction,
        and code generation. The questions are cleaned and curated from a variety of open source
        datasets. Each question has been reviewed by at least one of our domain experts both for
        legibility and relevance to real world tasks.
      </p>

      <div className="mt-7">
        <Tabs
          label="Question format"
          tabs={[
            {
              id: '1',
              label: 'Domain Knowledge',
              element: <CategoryDetail category="domain-knowledge" />,
            },
            {
              id: '2',
              label: 'Quantity Extraction',
              element: <CategoryDetail category="extraction" />,
            },
            {
              id: '3',
              label: 'Quantitative Reasoning',
              element: <CategoryDetail category="reasoning" />,
            },
          ]}
        />
      </div>
    </>
  )
}
