import {Icon} from '@kensho/neo'
import {useRef} from 'react'

interface FileUploadProps {
  accept?: string[]
  multiple?: boolean
  onSelect: (fileList: FileList | null) => void
}

export default function FileUpload(props: FileUploadProps): React.ReactNode {
  const {accept, multiple = false, onSelect} = props
  const inputRef = useRef<HTMLInputElement>(null)

  function handleButtonClick(): void {
    inputRef.current?.click()
  }

  return (
    <>
      <button
        className="flex w-full appearance-none flex-col gap-2 rounded-lg bg-blue-50 p-4 text-left"
        onClick={handleButtonClick}
        onDragOver={(event) => {
          // Allow this element to receive drop events
          event.preventDefault()
        }}
        onDrop={(event) => {
          // Prevent the default behavior of opening the file
          event.preventDefault()
          onSelect(event.dataTransfer.files)
        }}
        type="button"
      >
        <div className="text-brand-700">
          <Icon icon="PlusIcon" size="large" />
        </div>
        <div>Select a file or drag-and-drop</div>
        <div className="text-gray-500">Maximum file size 5 MB</div>
      </button>
      <input
        accept={accept ? accept.join(',') : undefined}
        multiple={multiple}
        onChange={(event) => {
          onSelect(event.target.files)
          // TODO: This is hacky and likely we should either fully control or leave the component uncontrolled.
          // Immediately clear the input since the consumer is responsible for keeping track of the input's value state.
          if (inputRef.current) inputRef.current.value = ''
        }}
        ref={inputRef}
        type="file"
        style={{display: 'none'}}
      />
    </>
  )
}
