import {ReactNode, useEffect} from 'react'
import {isRouteErrorResponse, useRouteError} from 'react-router-dom'
import {useLogger} from '@kensho/lumberjack'

interface ReportErrorProps {
  children?: ReactNode
}

/**
 * Reports route-level errors to a logging service.
 *
 * There are two types of route-level errors: those thrown by application code during render, and
 * those thrown by the router itself (e.g., due to an error in a loader, or when no route matches).
 * All application errors and all critical router errors should be reported, but non-critical
 * router errors (such as 404s) should be skipped.
 */
export default function ReportError(props: ReportErrorProps): React.ReactNode {
  const {children} = props
  const error = useRouteError()
  const log = useLogger()

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status < 500) return

    const message = error instanceof Error ? error : new Error('An unexpected error occurred')
    log.error(message)
  }, [error, log])

  return children
}
