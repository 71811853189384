import {Button, DropdownMenu} from '@kensho/neo'
import {useContext} from 'react'
import {Link} from 'react-router-dom'

import UserContext from '../providers/UserContext'
import getSignUpUrl from '../getSignUpUrl'
import getLoginUrl from '../getLoginUrl'
import {LINKS} from '../constants'

interface PageProps {
  children: React.ReactNode
}

const LOGOUT_ID = 'logout'
const COMPANY_NAME = 'Kensho Technologies, LLC.'
const CURRENT_YEAR = new Date().getFullYear()
const SOCIAL_URLS = {
  LINKEDIN: 'https://linkedin.com/company/kensho-technologies',
  TWITTER: 'https://twitter.com/kensho',
}

export default function Page(props: PageProps): React.ReactNode {
  const {children} = props
  const {user, logout} = useContext(UserContext)

  function handleDropdownAction(id: string): void {
    if (id === LOGOUT_ID) logout()
  }

  return (
    <>
      <header className="sticky top-0 z-10 flex h-20 items-center justify-between bg-blue-50 px-10">
        <div className="h-12">
          <Link to="/">
            <img src="/assets/logo.svg" alt="S&P AI Benchmarks by Kensho" />
          </Link>
        </div>
        <div className="flex gap-4">
          {user ? (
            <DropdownMenu
              onAction={handleDropdownAction}
              items={[{id: LOGOUT_ID, label: 'Log out'}]}
            >
              <Button minimal intent="primary" icon="UserCircleIcon">
                Hi {user.name}
              </Button>
            </DropdownMenu>
          ) : (
            <>
              <a href={getSignUpUrl()} rel="noopener">
                <Button intent="primary" minimal>
                  Sign up
                </Button>
              </a>
              <a href={getLoginUrl()} rel="noopener">
                <Button intent="primary">Sign in</Button>
              </a>
            </>
          )}
        </div>
      </header>
      <main>{children}</main>
      <footer className="bg-neutral-900 text-sm text-zinc-500 print:hidden">
        <div className="m-auto max-w-screen-lg px-4 py-16 lg:px-0">
          <div className="mb-6 flex justify-center md:justify-start">
            <img
              src="/assets/kensho-spg-white.svg"
              className="w-40"
              alt="Kensho, a company of S&amp;P Global"
              loading="lazy"
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col gap-4 text-center md:flex-row md:text-left">
              <div className="md:mr-10">
                <h4 className="mb-1 whitespace-pre text-sm uppercase text-white">
                  {'Harvard Square\n+ AI Lab'}
                </h4>
                <div>44 Brattle St</div>
                <div>Cambridge, MA 02138</div>
              </div>
              <div>
                <h4 className="mb-1 whitespace-pre text-sm uppercase text-white">New York City</h4>
                <div>55 Water Street</div>
                <div>New York, NY 10041</div>
              </div>
            </div>
            <div className="mt-12 flex-1 text-center md:mt-0 md:text-right">
              {/* eslint-disable react/jsx-no-target-blank */}
              <div>
                <Link className="mx-1 inline-block px-1 py-3" target="_blank" to="/agreement">
                  Data Agreement
                </Link>
                <a
                  className="mx-1 inline-block px-1 py-3"
                  target="_blank"
                  rel="noopener"
                  href="https://kensho.com/contact"
                >
                  Contact
                </a>
                <a
                  className="mx-1 inline-block px-1 py-3"
                  target="_blank"
                  rel="noopener"
                  href="https://kensho.com/privacy"
                >
                  Privacy Policy
                </a>
                <a
                  className="mx-1 inline-block px-1 py-3"
                  target="_blank"
                  rel="noopener"
                  href="https://kensho.com/terms-of-use"
                >
                  Web Terms
                </a>
                <a
                  className="mx-1 inline-block px-1 py-3"
                  target="_blank"
                  rel="noopener"
                  href="https://kensho.com/terms-of-service"
                >
                  Service Terms
                </a>
              </div>
              {/* eslint-enable react/jsx-no-target-blank */}
              <div className="flex justify-center md:justify-end">
                <a className="mx-1 inline-block px-1 py-3" href={LINKS.SUPPORT_EMAIL}>
                  Email
                </a>
                <a
                  className="mx-1 inline-block px-1 py-3"
                  target="_blank"
                  rel="noreferrer"
                  href={SOCIAL_URLS.LINKEDIN}
                >
                  LinkedIn
                </a>
                <a
                  className="mx-1 inline-block px-1 py-3"
                  target="_blank"
                  rel="noreferrer"
                  href={SOCIAL_URLS.TWITTER}
                >
                  Twitter
                </a>
              </div>
            </div>
          </div>
          <div className="mt-6 text-center">
            Copyright &copy; {CURRENT_YEAR} {COMPANY_NAME}. Kensho marks are the property of{' '}
            {COMPANY_NAME}. All rights reserved.
          </div>
        </div>
      </footer>
    </>
  )
}
