import {ENDPOINTS} from './constants'

/**
 * Builds the sign up URL to immediately redirect the user to the login page
 * after activation (via sign-up's return_to param) and also redirects them back to their
 * previous location after successful login (via login's next param).
 *
 * @returns The fully formed and encoded sign up URL
 */
export default function getSignUpUrl(): string {
  const loginParams = new URLSearchParams({next: window.location.pathname})
  const loginUrl = `https://${window.location.hostname}${ENDPOINTS.LOGIN}?${loginParams.toString()}`

  const signUpParams = new URLSearchParams({product: 'leaderboard', return_to: loginUrl})
  return `${ENDPOINTS.SIGN_UP}?${signUpParams.toString()}`
}
