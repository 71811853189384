import {NeoProvider} from '@kensho/neo'
import {Outlet, ScrollRestoration, useNavigate} from 'react-router-dom'

import UserProvider from '../providers/UserProvider'

export default function Root(): React.ReactNode {
  const navigate = useNavigate()
  return (
    <NeoProvider navigate={navigate}>
      <UserProvider>
        <ScrollRestoration />
        <Outlet />
      </UserProvider>
    </NeoProvider>
  )
}
