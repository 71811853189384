import {useReducer, useEffect, useMemo, useCallback} from 'react'
import {useLogger} from '@kensho/lumberjack'

import me from '../api/me'
import logout from '../api/logout'
import {User} from '../types'

import UserContext, {UserContextInterface} from './UserContext'

function assertNever(value: never, message = 'Unexpected value'): never {
  throw new Error(`${message}: ${value}`)
}

type UserAction = {type: 'logout'} | {type: 'updateUser'; user: User}

interface UserState {
  user?: User
  _state: UserContextInterface['_state']
}

function userReducer(_state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case 'updateUser':
      return {_state: 'known', user: action.user}
    case 'logout':
      return {_state: 'known'}
    default:
      return assertNever(action, 'Unknown action type')
  }
}

function getUser(dispatch: (action: UserAction) => void): void {
  me()
    .then((user) => {
      dispatch({type: 'updateUser', user})
    })
    .catch(() => dispatch({type: 'logout'}))
}

export default function UserProvider(props: {children: React.ReactNode}): React.ReactNode {
  const {children} = props
  const [{user, _state}, dispatch] = useReducer(userReducer, {_state: 'unknown'})
  const log = useLogger()

  const triggerUpstreamLogout = useCallback((): void => {
    logout().catch((error) => {
      log.error(error)
      // user should already be unset, but reset the state on an error just in case
      dispatch({type: 'logout'})
    })
  }, [log, dispatch])

  // attempt to retrieve the user on mount
  useEffect(() => {
    getUser(dispatch)
  }, [])

  const userContextValue = useMemo(
    () => ({
      user,
      _state,
      logout() {
        dispatch({type: 'logout'})
        triggerUpstreamLogout()
      },
    }),
    [_state, user, triggerUpstreamLogout],
  )

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
}
