function Base(): React.ReactNode {
  return (
    <svg width="30" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.754339" y1="9.20706" x2="16.0752" y2="0.5645" stroke="#005E74" />
      <path
        d="M15.7391 2.86709C15.8853 2.79008 16.0601 2.79029 16.2062 2.86767L26.7363 8.44697C27.0831 8.63071 27.0927 9.12404 26.7534 9.32115L16.2231 15.4375C16.0682 15.5275 15.8771 15.5277 15.722 15.4382L5.12876 9.32185C4.78792 9.12506 4.79754 8.62986 5.14576 8.44645L15.7391 2.86709Z"
        fill="url(#paint0_linear_146_44513)"
        stroke="#B62699"
      />
      <path d="M1 9.20654L15.9355 17.7286V23.3514L1 14.8293V9.20654Z" fill="#005E74" />
      <path
        d="M15.9358 17.7286L30.6956 9.20654V14.8293L15.9358 23.3514V17.7286Z"
        fill="url(#paint1_linear_146_44513)"
      />
      <line x1="16.1749" y1="0.565157" x2="30.7101" y2="8.81487" stroke="#018DB1" />
      <defs>
        <linearGradient
          id="paint0_linear_146_44513"
          x1="5.01874"
          y1="9.16963"
          x2="15.9358"
          y2="16.1607"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B62699" />
          <stop offset="1" stopColor="#63004F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_146_44513"
          x1="15.9358"
          y1="17.8164"
          x2="24.5457"
          y2="20.2764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A9D4" />
          <stop offset="1" stopColor="#007491" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function Layer(): React.ReactNode {
  return (
    <svg width="30" height="15" viewBox="0 0 30 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.855164L14.9355 9.37721V15L0 6.47795V0.855164Z" fill="#005E74" />
      <path
        d="M14.9358 9.37721L29.6956 0.855164V6.47795L14.9358 15V9.37721Z"
        fill="url(#paint0_linear_146_44255)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_146_44255"
          x1="14.9358"
          y1="9.46506"
          x2="23.5457"
          y2="11.925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A9D4" />
          <stop offset="1" stopColor="#007491" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function Unknown(): React.ReactNode {
  return (
    <svg width="30" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 0L29 8.52193L14.5 16.5351L0 8.52193L14.5 0Z" fill="#007694" />
      <path
        d="M0.0445557 8.50786L14.5005 16.5347V33.6934L0.0445557 25.7466V8.50786Z"
        fill="url(#paint0_linear_404_44527)"
      />
      <path
        d="M14.5013 16.5352L28.9572 8.37871V25.2656L14.5013 33.7739V16.5352Z"
        fill="url(#paint1_linear_404_44527)"
      />
      <path
        d="M6.51768 21.9966C6.51768 21.0586 7.21768 20.5686 7.86168 20.1066C8.47768 19.6866 9.05168 19.2946 9.05168 18.5806C9.05168 17.8806 8.50568 17.2786 7.39968 17.2786C6.34968 17.2786 5.69168 17.7406 5.17368 18.4266L4.47368 17.6706C5.14568 16.8026 6.18168 16.2426 7.51168 16.2426C9.21968 16.2426 10.2557 17.2086 10.2557 18.4126C10.2557 19.5886 9.42968 20.1626 8.68768 20.6806C8.08568 21.1006 7.53968 21.4646 7.53968 22.0806C7.53968 22.3046 7.67968 22.5846 7.91768 22.7666L7.06368 23.1306C6.68568 22.8086 6.51768 22.4166 6.51768 21.9966ZM6.58768 25.0906C6.58768 24.6706 6.93768 24.3206 7.35768 24.3206C7.77768 24.3206 8.12768 24.6706 8.12768 25.0906C8.12768 25.5106 7.77768 25.8606 7.35768 25.8606C6.93768 25.8606 6.58768 25.5106 6.58768 25.0906Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_404_44527"
          x1="7.27251"
          y1="8.50786"
          x2="2.4489"
          y2="27.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#004D61" />
          <stop offset="1" stopColor="#F2F2F2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_404_44527"
          x1="21.7293"
          y1="8.37888"
          x2="21.7293"
          y2="33.7739"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007694" />
          <stop offset="1" stopColor="#A7EDFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

interface SizeBucket {
  label: string
  display: React.ReactNode
}

const bucketConfigs = [
  {max: 1, label: '< 1 billion'},
  {max: 7, label: '1 - 7 billion'},
  {max: 13, label: '7 - 13 billion'},
  {max: 30, label: '13 - 30 billion'},
  {max: 70, label: '30 - 70 billion'},
  {max: Number.POSITIVE_INFINITY, label: '> 70 billion'},
]

const UNKNOWN_BUCKET = {label: 'Unknown', display: <Unknown />}

function getBucket(sizeBillions: number | null): SizeBucket {
  if (sizeBillions == null) return UNKNOWN_BUCKET

  const bucketIdx = bucketConfigs.findIndex((b) => sizeBillions < b.max)

  if (bucketIdx === -1) return UNKNOWN_BUCKET

  const display = (
    <>
      <Base />
      {Array(bucketIdx).fill(
        <div className="-mt-2">
          <Layer />
        </div>,
      )}
    </>
  )

  return {label: bucketConfigs[bucketIdx].label, display}
}

const sizeFormatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 1,
})

interface ModelSizeProps {
  sizeBillions: number | null
}

export default function ModelSize(props: ModelSizeProps): React.ReactNode {
  const {sizeBillions} = props

  const bucket = getBucket(sizeBillions)

  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col">{bucket.display}</div>
      <span className="text-gray-900">
        {sizeBillions == null ? 'unknown' : sizeFormatter.format(sizeBillions)}
      </span>
    </div>
  )
}
