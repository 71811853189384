import {RouteObject} from 'react-router-dom'

import Root from './layout/Root'
import HomePage from './pages/HomePage'
import SubmissionPage from './pages/SubmissionPage'
import ErrorPage from './pages/ErrorPage'
import Auth from './layout/Auth'
import AgreementPage from './pages/AgreementPage'

const routes: RouteObject[] = [
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {path: '/', element: <HomePage />},
      {path: '/agreement', element: <AgreementPage />},
      {element: <Auth />, children: [{path: '/submit', element: <SubmissionPage />}]},
    ],
  },
]

export default routes
