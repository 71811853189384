import {z} from 'zod'

export const LeaderboardSchema = z.array(
  z.object({
    id: z.string(),
    rank: z.number(),
    modelName: z.string(),
    organizationName: z.string().nullable(),
    organizationUrl: z.string().nullable(),
    domainKnowledgeScore: z.number(),
    quantityExtractionScore: z.number(),
    programSynthesisScore: z.number(),
    overallScore: z.number(),
    isHighlighted: z.boolean(),
    modelSizeBillions: z.number().nullable(),
  }),
)

export const UserSchema = z.object({
  email: z.string(),
  name: z.string(),
})

export const VoidSchema = z.void()

export type LeaderboardType = z.infer<typeof LeaderboardSchema>
export type UserType = z.infer<typeof UserSchema>
