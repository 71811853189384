import {Button} from '@kensho/neo'
import {useNavigate} from 'react-router-dom'

import Page from './Page'

export default function NotFoundPage(): React.ReactNode {
  const navigate = useNavigate()

  function navigateToHome(): void {
    navigate('/')
  }

  return (
    <Page>
      <div className="py-40">
        <div className="mb-14 flex flex-col items-center justify-center gap-6 md:flex-row">
          <svg
            width="328"
            height="132"
            viewBox="0 0 328 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M82.8407 122.279V97.4811H98.2947V75.0189H82.8407V2.42099H47.6199L0 77.355V97.4811H57.3236V122.279H82.8407ZM57.3236 75.0189H24.978L57.3236 25.0629V75.0189Z"
              fill="#007694"
            />
            <path
              d="M108.839 62.4401C108.839 93.3481 124.114 124.436 158.436 124.436C192.579 124.436 208.033 93.3481 208.033 62.4401C208.033 31.532 192.579 0.624008 158.436 0.624008C124.114 0.624008 108.839 31.532 108.839 62.4401ZM181.976 62.4401C181.976 84.5429 175.507 101.794 158.436 101.794C141.185 101.794 134.716 84.5429 134.716 62.4401C134.716 40.3372 141.185 23.2659 158.436 23.2659C175.507 23.2659 181.976 40.3372 181.976 62.4401Z"
              fill="#007694"
            />
            <path
              d="M301.496 122.279V97.4811H316.95V75.0189H301.496V2.42099H266.276L218.656 77.355V97.4811H275.979V122.279H301.496ZM275.979 75.0189H243.634L275.979 25.0629V75.0189Z"
              fill="#007694"
            />
            <path
              d="M92.329 129.3H92.7858V128.844V104.502H107.783H108.24V104.045V81.5831V81.1262H107.783H92.7858V8.98515V8.52831H92.329H57.1082H56.8572L56.7226 8.74012L9.10271 83.6741L9.03144 83.7863V83.9191V104.045V104.502H9.48828H66.355V128.844V129.3H66.8119H92.329ZM310.985 129.3H311.442V128.844V104.502H326.439H326.896V104.045V81.5831V81.1262H326.439H311.442V8.98515V8.52831H310.985H275.764H275.513L275.378 8.74012L227.758 83.6741L227.687 83.7863V83.9191V104.045V104.502H228.144H285.011V128.844V129.3H285.468H310.985ZM66.355 81.1262H35.3063L66.355 33.1732V81.1262ZM117.871 69.0042C117.871 84.5248 121.704 100.122 129.88 111.854C138.068 123.603 150.603 131.457 167.924 131.457C185.156 131.457 197.691 123.602 205.9 111.854C214.099 100.123 217.978 84.5258 217.978 69.0042C217.978 53.4826 214.099 37.9297 205.9 26.2433C197.69 14.5402 185.155 6.73133 167.924 6.73133C150.604 6.73133 138.069 14.5397 129.881 26.2438C121.704 37.9307 117.871 53.4836 117.871 69.0042ZM191.008 69.0042C191.008 80.024 189.393 89.7757 185.693 96.7557C182.008 103.706 176.266 107.901 167.924 107.901C159.491 107.901 153.704 103.703 149.998 96.7547C146.276 89.7752 144.661 80.0241 144.661 69.0042C144.661 57.9842 146.276 48.2787 149.998 41.3447C153.702 34.4421 159.489 30.2869 167.924 30.2869C176.268 30.2869 182.01 34.4399 185.693 41.3437C189.393 48.2782 191.008 57.9843 191.008 69.0042ZM285.011 81.1262H253.962L285.011 33.1732V81.1262Z"
              stroke="#00B9E8"
              strokeWidth="0.913685"
            />
          </svg>
          <div>
            <h1 className="text-5xl">Oops...</h1>
            <p className="text-2xl">Looks like this page doesn&apos;t exist</p>
          </div>
        </div>
        <div className="mx-auto flex justify-center">
          <Button onClick={navigateToHome} intent="primary">
            <div className="w-52">Back to homepage</div>
          </Button>
        </div>
      </div>
    </Page>
  )
}
