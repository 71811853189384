import {Button} from '@kensho/neo'
import {useNavigate} from 'react-router-dom'
import {useContext, useState} from 'react'

import LeaderboardTable from '../components/LeaderboardTable'
import UserContext from '../providers/UserContext'
import SignInDialog from '../components/SignInDialog'
import FrequentlyAskedQuestions from '../components/FrequentlyAskedQuestions'
import ExternalLink from '../components/ExternalLink'
import {LINKS} from '../constants'

import Page from './Page'

export default function HomePage(): React.ReactNode {
  const navigate = useNavigate()
  const {user} = useContext(UserContext)
  const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false)

  function handleSubmitClick(): void {
    if (!user) {
      setIsSignInDialogOpen(true)
    } else {
      navigate('/submit')
    }
  }

  return (
    <Page>
      <section className="mx-auto mt-8 max-w-screen-lg px-4 py-12 pt-0 lg:mt-32 lg:px-0">
        <h1 className="text-center text-4xl font-semibold text-brand-700">
          S&P AI Benchmarks by Kensho:
          <br />A Quantitative Reasoning Benchmark for Business and Finance
        </h1>
        <h2 className="mt-5 text-center text-2xl font-medium">
          An industry-specific benchmark intended to help AI teams evaluate their Large Language
          Models’ (LLMs) ability to understand and solve realistic finance problems.
        </h2>
        <p className="mt-8 text-xl">
          The S&P AI Benchmarks by Kensho project is informed by S&P Global’s world-class data and
          industry expertise. Each question has been verified by domain experts – finance
          professionals with 5+ years of experience. Everyone is welcome to sign up and participate,
          from academic labs and large corporations to independent language model developers. The
          public-facing leaderboard is designed to encourage innovation and collaborative
          understanding.
        </p>
        <div className="mt-16 flex justify-center">
          <Button intent="primary" size="large" onClick={handleSubmitClick}>
            <div className="w-72">Get started</div>
          </Button>
        </div>
      </section>

      <section className="mx-auto max-w-screen-xl px-4 py-12">
        <h3 className="text-2xl font-semibold">Leaderboard</h3>
        <div className="mt-4 overflow-x-auto overflow-y-hidden">
          <LeaderboardTable />
        </div>
      </section>

      <section className="mt-12 w-full bg-gray-50">
        <div className="mx-auto max-w-screen-lg px-4 py-12 lg:px-0">
          <h3 className="text-center text-2xl font-semibold">How We Created S&P AI Benchmarks</h3>
          <p className="mt-12 text-xl">
            This benchmark is composed of six question-answering tasks that span three categories of
            assessment: core domain knowledge, quantity extraction, and quantitative reasoning. This
            evaluation is developed by financial and machine learning professionals, including
            Kensho’s R&D team (NLP researchers) and S&P Global team members from Market
            Intelligence, Ratings, and Corporate Finance teams.
          </p>
          <p className="mt-7 text-xl">
            Performance on S&P AI Benchmarks by Kensho is intended to capture how well your model
            can:
          </p>
          <div className="mt-12 grid grid-cols-1 gap-12 md:grid-cols-3">
            <div className="flex flex-col items-center">
              <div className="flex h-40 items-center">
                <img
                  alt="Complex quantitative reasoning"
                  src="/assets/complex-quant.svg"
                  loading="lazy"
                />
              </div>
              <p className="mt-2 text-xl">
                Solve complex quantitative reasoning questions that require mastery of financial
                formulae.
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex h-40 items-center">
                <img
                  // Note: This fixes issues with mix-blend-mode in safari
                  // See https://stackoverflow.com/questions/70891365/css-mix-blend-mode-difference-issue-in-safari
                  style={{transform: 'translate3d(0,0,0)'}}
                  alt="Understanding blocks"
                  src="/assets/understanding.svg"
                  loading="lazy"
                />
              </div>
              <p className="mt-2 text-xl">Understand fundamentals of finance and business.</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex h-40 items-center">
                <img alt="Extraction" src="/assets/extract.svg" loading="lazy" />
              </div>
              <p className="mt-2 text-xl">Extract relevant information from documents.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mx-auto max-w-screen-lg px-4 py-24 lg:px-0">
        <div className="flex flex-col-reverse items-center gap-20 md:flex-row">
          <div>
            <h3 className="text-2xl font-semibold">Why We Created S&P AI Benchmarks</h3>
            <p className="mt-5 text-xl">
              Although today’s LLMs generally demonstrate strong performance on question-answering
              (QA) and code generation tasks, it remains difficult for models to reason about
              quantities and numbers. This poses issues for using LLMs for real-world applications
              in business and finance, as these fields can require transparent and precise reasoning
              capabilities, along with a wide breadth of technical knowledge.
            </p>
          </div>
          <img
            // Note: This fixes issues with mix-blend-mode in safari
            // See https://stackoverflow.com/questions/70891365/css-mix-blend-mode-difference-issue-in-safari
            style={{transform: 'translate3d(0,0,0)'}}
            alt="Training models"
            src="/assets/training-models.svg"
            className="flex-none"
            loading="lazy"
          />
        </div>
        <div className="mt-12 flex flex-col items-center gap-20 md:flex-row">
          <img
            alt="Establishing trust"
            src="/assets/trust.svg"
            className="flex-none"
            loading="lazy"
          />
          <div>
            <p className="mt-5 text-xl">
              Existing benchmarks for these domains include tasks such as sentiment analysis, text
              classification, or named-entity extraction. Our goal with S&P AI Benchmarks is to
              create rigorous and challenging tasks that are rooted in realistic use-cases for
              professionals. Ultimately, our goals are to establish a trustworthy, objective
              evaluation and to facilitate the development of better models for business and
              finance.
            </p>
            <p className="mt-6 text-xl font-medium">
              To learn more, read our{' '}
              <ExternalLink to={LINKS.RESEARCH_PAPER}>latest research paper</ExternalLink>.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-12 w-full bg-blue-50 px-4 py-24 lg:px-0">
        <div className="flex flex-col items-center justify-center gap-9 px-4 text-center md:flex-row md:text-left">
          <h2 className="text-3xl font-semibold text-brand-700">
            Ready to find your place on the leaderboard?
          </h2>
          <div className="flex-none">
            <Button intent="primary" size="large" onClick={handleSubmitClick}>
              <div className="w-64">Get started</div>
            </Button>
          </div>
        </div>
      </section>

      <section className="m-auto max-w-4xl px-4 py-24 lg:px-0">
        <h2 className="mb-10 text-center text-2xl font-semibold">Frequently Asked Questions</h2>
        <FrequentlyAskedQuestions />
      </section>

      <SignInDialog isOpen={isSignInDialogOpen} onClose={() => setIsSignInDialogOpen(false)} />
    </Page>
  )
}
