import {ENDPOINTS} from './constants'

/**
 * Builds a login URL that returns the user back to their current path.
 *
 * @returns The login URL with the current path encoded into the next param
 */
export default function getLoginUrl(): string {
  return `${ENDPOINTS.LOGIN}?next=${encodeURIComponent(window.location.pathname)}`
}
