import {RouteObject, RouterProviderProps} from 'react-router-dom'

import {wrappedCreateBrowserRouter} from '../sentry'

import ReportError from './ReportError'

function enhanceRoute(route: RouteObject, isRoot: boolean): RouteObject {
  return {
    ...route,
    errorElement: (isRoot || route.errorElement) && <ReportError>{route.errorElement}</ReportError>,
    children: route.children?.map((child) => enhanceRoute(child, false)),
  } as RouteObject
}

/**
 * Wraps a list of application routes to add the following features:
 *
 * 1. All route error boundaries are augmented to report caught errors to a logging service.
 * 2. All root routes are augmented to include that logging error boundary if they don't already
 * declare their own.
 * 3. A final fallback error boundary is added as a last resort.
 */
function enhanceRoutes(routes: RouteObject[]): RouteObject[] {
  return [...routes.map((route) => enhanceRoute(route, true)), {errorElement: <ReportError />}]
}

export default function createRouter(routes: RouteObject[]): RouterProviderProps['router'] {
  return wrappedCreateBrowserRouter(enhanceRoutes(routes))
}
