import {Icon, Spinner, Table} from '@kensho/neo'
import clsx from 'clsx'
import {useFetch} from '@kensho/tacklebox'
import {useLogger} from '@kensho/lumberjack'
import {useCallback, useState} from 'react'
import {ZodError} from 'zod'
import {twMerge} from 'tailwind-merge'

import {Leaderboard} from '../types'
import {LeaderboardSchema} from '../schemas'
import camelCaseKeys from '../camelCaseKeys'

import ModelSize from './ModelSize'
import Organization from './Organization'

interface RankProps {
  rank: number
}

const rankColors = ['bg-amber-400', 'bg-neutral-300', 'bg-[#B08D57]']
function Rank(props: RankProps): React.ReactNode {
  const {rank} = props

  return (
    <div
      className={clsx(
        'ml-1 flex size-9 items-center justify-center rounded-full',
        rank < 4 && [rankColors[rank - 1], 'text-white'],
      )}
    >
      {rank}
    </div>
  )
}

const scoreFormatter = new Intl.NumberFormat('en-us', {
  maximumFractionDigits: 2,
})
interface ScoreProps {
  score: number
}

function Score(props: ScoreProps): React.ReactNode {
  const {score} = props
  return <span className="text-sm text-gray-900">{scoreFormatter.format(score * 100)}</span>
}

interface CellProps {
  className?: string
  children: React.ReactNode
  isHighlighted: boolean
}

function Cell(props: CellProps): React.ReactNode {
  const {className, children, isHighlighted} = props
  return (
    <div
      className={clsx(
        isHighlighted && 'bg-blue-50',
        twMerge('-mx-3 -my-4 flex min-h-20 items-center px-3 py-2', className),
      )}
    >
      {children}
    </div>
  )
}

interface SortableHeaderProps {
  label: React.ReactNode
  sortDir: 'ascending' | 'descending' | null
  onSort: () => void
}

function SortableHeader(props: SortableHeaderProps): React.ReactNode {
  const {label, sortDir, onSort} = props
  return (
    <button
      type="button"
      onClick={onSort}
      className="group inline-flex cursor-pointer gap-1 text-left"
    >
      {label}
      <span
        className={clsx(
          'rounded group-hover:visible group-focus:visible',
          sortDir == null && 'invisible',
          sortDir != null && 'bg-gray-100 group-hover:bg-gray-200',
        )}
        aria-hidden={sortDir == null}
      >
        <Icon icon={sortDir === 'ascending' ? 'ChevronUpIcon' : 'ChevronDownIcon'} />
      </span>
    </button>
  )
}

type SortKey = keyof Leaderboard[0]

type ColumnSort = {
  key: SortKey
  dir: 'ascending' | 'descending'
}

// All sortable columns are numeric except for modelName. Values of `null`
// are always sorted at the end regardless of sort direction.
// Note: We assume the values are numeric if the sort key is not modelName
function getSortedItems(items: Leaderboard, sort: ColumnSort): Leaderboard {
  const itemsToSort = [...items]

  if (sort.key === 'modelName') {
    return itemsToSort.sort((a, b) =>
      sort.dir === 'ascending'
        ? a.modelName.localeCompare(b.modelName)
        : b.modelName.localeCompare(a.modelName),
    )
  }

  return itemsToSort.sort((a, b) => {
    const aValue = a[sort.key] as number
    const bValue = b[sort.key] as number

    if (aValue == null && bValue == null) return 0
    if (aValue == null) return 1
    if (bValue == null) return -1
    return sort.dir === 'ascending' ? aValue - bValue : bValue - aValue
  })
}

export default function LeaderboardTable(): React.ReactNode {
  const log = useLogger()
  const [columnSort, setColumnSort] = useState<ColumnSort>({
    key: 'overallScore',
    dir: 'descending',
  })

  function handleSort(key: SortKey): () => void {
    return () => {
      setColumnSort((prevSort) =>
        key === prevSort.key
          ? {key, dir: prevSort.dir === 'ascending' ? 'descending' : 'ascending'}
          : {key, dir: 'descending'},
      )
    }
  }

  const parseLeaderboardResponse = useCallback(
    (response: Response): Promise<Leaderboard> => {
      if (!response.ok) {
        log.error('Failed to fetch leaderboard', {
          status: response.status,
          statusText: response.statusText,
        })
        throw response
      }
      return response
        .json()
        .then((data) => LeaderboardSchema.parse(camelCaseKeys(data)))
        .catch((error) => {
          const context = error instanceof ZodError ? {issues: JSON.stringify(error.issues)} : error
          log.error('Failed to parse leaderboard', context)
          throw error
        })
    },
    [log],
  )

  const asyncLeaderboard = useFetch<Leaderboard>(
    '/api/v1/leaderboard',
    undefined,
    parseLeaderboardResponse,
  )

  if (asyncLeaderboard.status === 'pending') {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    )
  }

  if (asyncLeaderboard.status === 'error') {
    return (
      <div className="flex justify-center">
        <p>Unable to fetch leaderboard. Please refresh to try again.</p>
      </div>
    )
  }

  if (asyncLeaderboard.value.length === 0) {
    // TODO: Confirm with design.
    return <p className="text-center">No results available</p>
  }

  const sortedItems = getSortedItems(asyncLeaderboard.value, columnSort)

  return (
    <>
      <Table
        columns={[
          {
            id: 'rank',
            label: 'Rank',
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <Rank rank={entry.rank} />
              </Cell>
            ),
          },
          {
            id: 'name',
            label: (
              <SortableHeader
                label="Model Name"
                sortDir={columnSort.key === 'modelName' ? columnSort.dir : null}
                onSort={handleSort('modelName')}
              />
            ),
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <span className={clsx('text-gray-900', entry.rank < 4 && 'font-bold')}>
                  {entry.modelName}
                </span>
              </Cell>
            ),
          },
          {
            id: 'organization',
            label: 'Organization',
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <Organization name={entry.organizationName} website={entry.organizationUrl} />
              </Cell>
            ),
          },
          {
            id: 'size',
            label: (
              <SortableHeader
                label="Model Size (in billions)"
                sortDir={columnSort.key === 'modelSizeBillions' ? columnSort.dir : null}
                onSort={handleSort('modelSizeBillions')}
              />
            ),
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <ModelSize sizeBillions={entry.modelSizeBillions} />
              </Cell>
            ),
          },
          {
            id: 'domainKnowledge',
            label: (
              <SortableHeader
                label="Domain Knowledge (%)"
                sortDir={columnSort.key === 'domainKnowledgeScore' ? columnSort.dir : null}
                onSort={handleSort('domainKnowledgeScore')}
              />
            ),
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <Score score={entry.domainKnowledgeScore} />
              </Cell>
            ),
          },
          {
            id: 'quantityExtraction',
            label: (
              <SortableHeader
                label="Quantity Extraction (%)"
                sortDir={columnSort.key === 'quantityExtractionScore' ? columnSort.dir : null}
                onSort={handleSort('quantityExtractionScore')}
              />
            ),
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <Score score={entry.quantityExtractionScore} />
              </Cell>
            ),
          },
          {
            id: 'programSynthesis',
            label: (
              <SortableHeader
                label="Program Synthesis (%)"
                sortDir={columnSort.key === 'programSynthesisScore' ? columnSort.dir : null}
                onSort={handleSort('programSynthesisScore')}
              />
            ),
            render: (entry) => (
              <Cell isHighlighted={entry.isHighlighted}>
                <Score score={entry.programSynthesisScore} />
              </Cell>
            ),
          },
          {
            id: 'overall',
            label: (
              <SortableHeader
                label="Overall (%)"
                sortDir={columnSort.key === 'overallScore' ? columnSort.dir : null}
                onSort={handleSort('overallScore')}
              />
            ),
            render: (entry) => (
              <Cell className="-mr-0 pr-0" isHighlighted={entry.isHighlighted}>
                <Score score={entry.overallScore} />
              </Cell>
            ),
          },
        ]}
        getItemKey={(entry) => entry.id}
        items={sortedItems}
      />
      <p className="mt-4 text-[10px] text-gray-500">
        Disclaimer: All trademarks and logos displayed are property of their respective owners. S&P
        Global’s use of such marks does not imply any affiliation or endorsement by their owners.
        Leaderboard rank and results are not a guarantee of the associated LLM’s accuracy,
        performance or reliability. Benchmark results are for information only and not advice or
        recommendations. They are produced using methodologies incorporating AI tools and provided
        “as-is” without any express or implied warranties by S&P and its affiliates regarding
        accuracy, reliability, or completeness.
      </p>
    </>
  )
}
