interface ExternalLinkProps {
  children: React.ReactNode
  to: string
}

export default function ExternalLink(props: ExternalLinkProps): React.ReactNode {
  const {children, to} = props
  return (
    <a className="text-brand-700 underline" href={to} rel="noreferrer noopener" target="_blank">
      {children}
    </a>
  )
}
