import {createContext} from 'react'

import {User} from '../types'

export interface UserContextInterface {
  user?: User
  logout: () => void
  _state: 'known' | 'unknown'
}

export default createContext<UserContextInterface>({
  logout: () => null,
  _state: 'unknown',
})
