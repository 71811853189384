import {Outlet} from 'react-router-dom'
import {useContext} from 'react'
import {Spinner} from '@kensho/neo'

import UserContext from '../providers/UserContext'
import Page from '../pages/Page'
import SignInDialog from '../components/SignInDialog'

export default function Auth(): React.ReactNode {
  const {user, _state: userState} = useContext(UserContext)

  if (userState === 'unknown') {
    return (
      <div className="mt-4 flex w-full justify-center">
        <Spinner intent="primary" />
      </div>
    )
  }

  if (!user) {
    return (
      <Page>
        <SignInDialog />
      </Page>
    )
  }

  return <Outlet />
}
